export const RULES = {
  required(value) { return !!value || this.$t('global.required'); },
  requiredArray(value) { return (value && value.length !== 0) || this.$t('global.required'); },
  email(value) {
    if (!value) return true;
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || this.t('invalidEmail');
  },
};

export const RULES_FOR_FIELDS = {
  required: ['String', 'Number', 'Date', 'Select', 'User', 'Markdown'],
  requiredArray: ['Link', 'Attachment', 'CoverImage', 'MultipleSelect'],
  email: 'User',
};

const VALIDATORS = {
  max_items({ rule }, value) {
    if (value && value.length > rule.value) {
      return this.t('errors.records.field_value_has_too_many_items');
    }

    return true;
  },
  min_items({ rule }, value) {
    if (value && value.length < rule.value) {
      return this.t('errors.records.field_value_has_too_few_items');
    }

    return true;
  },
  max_characters({ rule, field }, value) {
    const valueToCheck = (field.type === 'Markdown' ? this.$markdownToText(value) : value) || '';

    if (valueToCheck.length > rule.value) {
      return this.$t('errors.records.field_value_has_too_many_characters');
    }

    return true;
  },
  min_characters({ rule, field }, value) {
    const valueToCheck = (field.type === 'Markdown' ? this.$markdownToText(value) : value) || '';

    if (valueToCheck.length < rule.value) {
      return this.$t('errors.records.field_value_has_too_few_characters');
    }

    return true;
  },
};

export const fieldMixin = {
  computed: {
    fieldMixin__fieldRules() {
      return this.fieldMixin__findFieldRules(this.field);
    },
    fieldMixin__maxCharacters() {
      return this.fieldMixin__findRule(this.field, 'max_characters')?.value || null;
    },
  },
  methods: {
    fieldMixin__findFieldRules(field) {
      const rules = [];

      Object.keys(VALIDATORS).forEach((ruleType) => {
        const rule = this.fieldMixin__findRule(field, ruleType);
        const validator = VALIDATORS[ruleType];

        if (rule) {
          rules.push(validator.bind(this, { rule, field }));
        }
      });

      return rules;
    },
    fieldMixin__findRule(field, ruleType) {
      return field?.rules?.find(rule => rule.type === ruleType);
    },
  },
};
